<template>
  <div class="home">
    <a-carousel class="home-slider" :autoplay="true" :key="updateNum">
      <template v-for="(item, index) in slider" :key="index">
        <div class="slider-img-wrap">
          <img :src="item.slider_image" alt="" class="slider-img" />
        </div>
      </template>
    </a-carousel>
    <div class="nav-wrap">
      <div class="nav flex">
        <div @click="goToPage('/park')">
          <img src="~@/assets/home-icon-1.png" alt="" />
          <div>园区信息</div>
        </div>
        <div @click="goToPage('/enterprise')">
          <img src="~@/assets/home-icon-2.png" alt="" />
          <div>企业入驻</div>
        </div>
        <div @click="goToPage('/enterprise/formList')">
          <img src="~@/assets/home-icon-3.png" alt="" />
          <div>数据填报</div>
        </div>
        <!-- <div @click="goToPage('/payment')">
          <img src="~@/assets/home-icon-4.png" alt="" />
          <div>费用缴纳</div>
        </div> -->
        <div @click="goToPage('/repair')">
          <img src="~@/assets/home-icon-5.png" alt="" />
          <div>报事报修</div>
        </div>
        <!-- <div @click="goToPage('/job')">
          <img src="~@/assets/home-icon-6.png" alt="" />
          <div>招聘服务</div>
        </div> -->
        <div @click="goToPage('/meeting')">
          <img src="~@/assets/home-icon-7.png" alt="" />
          <div>会议室预定</div>
        </div>
        <div @click="goToPage('/studio')">
          <img src="~@/assets/home-icon-7.png" alt="" />
          <div>直播室预定</div>
        </div>
        <div @click="goToPage('/provider')">
          <img src="~@/assets/home-icon-8.png" alt="" />
          <div>咨询服务</div>
        </div>
        <div @click="goExamAndCourse">
          <img src="~@/assets/home-icon-9.png" alt="" />
          <div>在线学习</div>
        </div>
        <div @click="goToPage('/declaration')">
          <img src="~@/assets/home-icon-10.png" alt="" />
          <div>申报服务</div>
        </div>
      </div>
    </div>
    <!-- <div class="flex flex-between" style="width: 1200px; margin: 40px auto 0 auto">
      <div class="data-every" v-for="(item, i) in showData" :key="i">
        {{ item[0]}}<span style="font-weight: bold;">{{ item[1] }}</span>{{ item[2] }}
      </div>
    </div> -->
    <div class="wrapper">
      <div class="part-title flex flex-between">
        <div class="title">
          <span class="title-cn">通知公告</span>
          <span class="title-en">NOTIFICATION ANNOUNCEMENT</span>
        </div>
        <div class="more" @click="goToPage('/notice')">查看全部 >></div>
      </div>
      <div class="notice-box flex flex-between">
        <div
          v-for="(item, index) in notice"
          :key="index"
          class="notice-item flex flex-between"
          @click="goToNoticeDeatil(item)"
        >
          <div class="notice-title">{{ item.notice_title }}</div>
          <div class="notice-time">{{ item.notice_time }}</div>
        </div>
      </div>
      <div class="flex flex-between home-bottom">
        <img
          src="~@/assets/home-activity.png"
          alt=""
          @click="goToPage('/activity')"
        />
        <img
          src="~@/assets/home-data.png"
          alt=""
          @click="showdata"
        />
      </div>
    </div>
    <!-- // -->
    <a-modal
      v-model:visible="examvisiblie"
      :footer="null"
      :closable="true"
      centered
      width="900px"
      @cancel="examvisiblie = false"
      wrapClassName="exam-box-wrap"
    >
      <div style="">
        <div class="exam-box">
          <div class="exam-cont flex">
            <div class="flex" style="width: 400px; justify-content: center">
              <div class="cont-e">
                <img src="~@/assets/exam.png" />
                <div class="text">课程培训</div>
                <div class="btnss" @click="goToPage('/course/index')">
                  点击进入
                </div>
              </div>
            </div>
            <div class="liness"></div>
            <div class="flex" style="width: 400px; justify-content: center">
              <div class="cont-e">
                <img src="~@/assets/course.png" />
                <div class="text">测评考试</div>
                <div class="btnss" @click="goToPage('/exam/index')">
                  点击进入
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <!-- // -->
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  ref,
} from "@vue/runtime-core";
import { getSliderList, getNoticeList } from "@/api/main.js";
import { message, Modal } from "ant-design-vue";
import url from "@/utils/url.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import { useStore } from "vuex";
import { encode } from "js-base64";

export default defineComponent({
  name: "Home",
  components: {},
  setup() {
    const store = useStore();

    const pageData = reactive({
      slider: [],
      notice: [],
      user_id: computed(() => getUserId()),
      userData: computed(() => getUserData()),
      updateNum: 0,
    });
    const goToPage = (_page) => {
      url.navigateTo(_page);
    };
    const goToNoticeDeatil = (_item) => {
      url.navigateTo("/notice/detail", {
        t: encode(`${_item.notice_id}`),
      });
    };
    const getSliderListFun = () => {
      getSliderList({
        slider_type_id: 1,
        park_id: pageData.userData?.user_park_id,
      })
        .then((res) => {
          if (res.code == 200) {
            pageData.slider = res.data.slider;
            pageData.updateNum++;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        });
    };
    const getNoticeListFun = () => {
      getNoticeList({
        page: 1,
        limit: 8,
        park_id: pageData.userData?.user_park_id,
      })
        .then((res) => {
          if (res.code == 200) {
            pageData.notice = res.data.notice;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        });
    };
    watch(
      () => pageData.userData.user_park_id,
      (val) => {
        getSliderListFun();
        getNoticeListFun();
      },
      {
        immediate: true,
        deep: true,
      }
    );
    const examvisiblie = ref(false);
    const goExamAndCourse = () => {
      examvisiblie.value = true;
    };
    const showData = [
      ["营业收入：",11.93,"亿元"],
      ["税收：",1057.29,"万元"],
      ["入驻机构：",27,"家"],
     [ "带动就业：",56476,"万人"],
    ];

    const showdata=()=>{
      window.open('https://tscyydv.qinghuiche.com/');
      // Modal.info({
      //     title: "暂未上线，敬请期待！",
      //     okText: "确定",
      //     centered: true,
      //     centered:true,
      //     onOk() {}
      //   });
    }
    return {
      ...toRefs(pageData),
      goToPage,
      goToNoticeDeatil,
      goExamAndCourse,
      examvisiblie,
      showData,showdata
    };
  },
});
</script>
<style lang="less">
.data-every{
  width: 270px;
  height: 80px;
  background:linear-gradient(to bottom, #1276cb,#5ab0f7);
  color: white;
  border-radius: 15px;
  text-align: center;
  line-height: 80px;
  font-size: 22px;
}
.home {
  .home-slider {
    width: 100%;
    .slider-img-wrap {
      height: 500px;
      width: 100%;
      position: relative;
      background: #ffffff;
      .slider-img {
        object-fit: contain;
        object-position: center;
        overflow: hidden;
        height: 501px;
        margin: 0 auto;
        display: block;
      }
    }
  }
  .nav-wrap {
    height: 120px;
    background-color: #1276cb;
    .nav {
      width: 1200px;
      margin: 0 auto;
      height: 120px;
      align-items: center;
      justify-content: space-between;
      color: #ffffff;
      font-size: 20px;
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        img {
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }
  .part-title {
    margin-top: 40px;
    padding: 20px 0;
    border-bottom: 2px solid #f6f8f9;
    .title {
      position: relative;
      padding-left: 20px;
      .title-cn {
        font-size: 30px;
        font-weight: bold;
        color: #212531;
      }
      .title-en {
        color: #212531;
        opacity: 0.6;
        font-size: 16px;
        margin-left: 14px;
        vertical-align: baseline;
      }
      :before {
        content: "";
        position: absolute;
        width: 4px;
        height: 20px;
        background-color: #1276cb;
        border-radius: 2px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .more {
      font-size: 18px;
      color: #1276cb;
      cursor: pointer;
    }
  }
  .notice-box {
    margin-top: 50px;
    flex-wrap: wrap;
    .notice-item {
      width: 575px;
      margin-bottom: 35px;
      position: relative;
      padding-left: 20px;
      box-sizing: border-box;
      cursor: pointer;
      :before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        background-color: #1276cb;
        border-radius: 6px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .notice-title {
        color: #212531;
        font-size: 20px;
        width: 425px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .notice-time {
        color: #212531;
        opacity: 0.7;
        font-size: 16px;
      }
    }
  }
  .home-bottom {
    margin: 35px 0 0 0;
    padding-bottom: 150px;
    img {
      cursor: pointer;
    }
  }
}
//
.exam-box-wrap {
  .ant-modal-content {
    width: 900px;
    height: 490px;
    background-image: linear-gradient(
        0deg,
        #ffffff 0%,
        rgba(18, 118, 203, 0.3) 100%
      ),
      linear-gradient(#ffffff, #ffffff);
    background-blend-mode: normal, normal;
    border-radius: 20px;
    .exam-box {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 46px;
      .liness {
        position: absolute;
        width: 0;
        height: 186px;
        border-left: solid 2px #5e6370;
        opacity: 0.3;
        top: 157px;
        left: 449px;
        cursor: pointer;
        z-index: 10;
      }
      .exam-cont {
        width: 800px;
        height: 350px;
        background-color: #ffffff;
        border-radius: 10px;
        display: flex;
        overflow: hidden;
        .cont-e {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 20px;
          img {
            height: 142px;
            margin-bottom: 26px;
          }
          .text {
            line-height: 1;
            margin-bottom: 25px;
          }
          .btnss {
            width: 240px;
            height: 50px;
            background-color: #1276cb;
            border-radius: 25px;
            color: white;
            text-align: center;
            line-height: 50px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

//
</style>
